import dayjs from "dayjs"
import React from "react"
import { Box, Flex, Image, Text } from "theme-ui"

const AuthorBadge = ({ author, createdAt, small, smaller, whiteText }) => {
  const authorLookup = {
    GoNation:
      "https://res.cloudinary.com/gonation/w_375,c_fit,fl_lossy,f_auto,q_auto/gonation.data.prod/vwjsonjkzqzti407kgz9",
    Jackie: "",
    Niki: "",
    Amy: "",
    Chaz: "",
  }

  const authorProfileImageLookup = () => {
    if (authorLookup[author]) {
      return authorLookup[author]
    }
    return "https://res.cloudinary.com/gonation/w_150,c_fit,fl_lossy,f_auto,q_auto/gonation.data.prod/default/img-usr-avatar-full.png"
  }

  return (
    <Flex sx={{ mt: 3, alignItems: "center" }}>
      <Box>
        <Image
          variant="avatar"
          alt={author}
          src={authorProfileImageLookup()}
          sx={small ? smallStyle : null}
        ></Image>
      </Box>
      <Box sx={{ pl: 3, color: whiteText ? "#fff" : "muted" }}>
        <Text sx={{ fontSize: smaller ? 0 : "inherit" }} as="p">
          {author}
        </Text>
        <Text sx={{ fontSize: smaller ? 0 : "inherit" }} as="p">
          {dayjs(createdAt).format("MMMM D, YYYY")}
        </Text>
      </Box>
    </Flex>
  )
}

export default AuthorBadge

const smallStyle = {
  width: "40px",
  height: "40px",
}
