import React from "react"
import { Box, AspectImage, Heading, Text } from "theme-ui"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import slugify from "slugify"

import { CLOUDINARY_BASE } from "../constants"
import AuthorBadge from "./AuthorBadge"
import TagsRow from "./TagsRow"
import { cloudinaryOptimizer, getCloudinaryImage } from "../helpers"

const Card = ({ data }) => {
  const { title, media, tags, author, description, createdAt } = data
  return (
    <AniLink
      paintDrip
      to={`/posts/${slugify(title, { lower: true })}`}
      hex="#20bed6"
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      //   sx={cardStyle}
    >
      <Box>
        <AspectImage
          ratio={19 / 9}
          sx={{ width: "100%", objectFit: "cover" }}
          alt={title}
          //   src={` ${CLOUDINARY_BASE}/${media[0].cloudinaryId}`}
          src={cloudinaryOptimizer(getCloudinaryImage(media[0].cloudinaryId))}
        ></AspectImage>
      </Box>
      <Box sx={{ paddingY: 3, paddingX: 2, minHeight: 224 }}>
        <TagsRow tags={tags} />
        <Heading as="p" sx={{ fontSize: 4, mt: 2 }}>
          {title}
        </Heading>
        <Text variant="light" sx={{ fontSize: [1], minHeight: "67px" }}>
          {description}
        </Text>
        <AuthorBadge
          small
          smaller
          author={author}
          createdAt={createdAt}
        ></AuthorBadge>
      </Box>
    </AniLink>
  )
}

export default Card

// todo remove if site goes live and this is still here
// const cardStyle = {
//   transition: "all 1s",
//   cursor: "pointer",
//   bg: "isWhite",
//   textDecoration: "none",
//   color: "inherit",
//   display: "block",
//   "&:hover": {
//     boxShadow: "0 0 12px rgba(0,0,0,.3)",
//     transition: "all 1s",
//   },
// }
