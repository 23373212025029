import React from "react"
import { Flex, Text } from "theme-ui"

const TagsRow = ({ tags = [] }) => {
  return (
    <Flex>
      {tags.map(tag => (
        <Text key={tag} variant="tags" as="span">
          {tag}
        </Text>
      ))}
    </Flex>
  )
}

export default TagsRow
